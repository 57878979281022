var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"reportTable"}},[_c('div',{staticClass:"title"},[_c('h3',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.data.full_name)+" "+_vm._s(_vm.title))])]),_c('table',[(_vm.home != true)?_c('thead',{style:({ 'background-color':  _vm.reportColour})},[_c('tr',[[_c('th',[_vm._v(" No ")]),(this.home === false)?_c('th',[_vm._v(" Country ")]):_vm._e(),_c('th',[_vm._v(" Player(s) ")]),_c('th',[_vm._v(" Entry Status ")]),(this.home === false)?_c('th',[_vm._v(" Exempt Rank ")]):_vm._e(),(_vm.config.VUE_APP_PLAYER_ICON === 'Y')?_c('th'):_vm._e()]],2)]):_vm._e(),_c('tbody',[[_vm._l((_vm.Eds),function(tabledata,index){return [_c('tr',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(index)}}},[(
              tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
            )?_c('td',{staticClass:"posnum",class:{homeEntriesPos: _vm.home === true}},[_vm._v(" "+_vm._s(tabledata.serial_no)+" ")]):_vm._e(),(tabledata.playerno === 'Cat')?_c('td',{staticClass:"catD",class:{catSection: _vm.home === true},attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(tabledata.exempt_desc)+" ")]):_vm._e(),(tabledata.playerno === 'EOF')?_c('td',{staticClass:"cut",attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(tabledata.exempt_desc)+" ")]):_vm._e(),(_vm.home === false)?[(
                tabledata.pos !== 'CUT' &&
                  tabledata.playerno !== 'Cat' &&
                  tabledata.playerno !== 'EOF'
              )?_c('td',{staticClass:"mobHide"},[(tabledata.nationality === '')?_c('span'):_c('span',[_c('img',{staticClass:"flag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + tabledata.nationality))}}),_vm._v(" "+_vm._s(tabledata.represents)+" ")])]):_vm._e()]:_vm._e(),(
              tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
            )?_c('td',[_c('a',{staticClass:"nameLink",class:{ homeEntriesName: _vm.home === true},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(tabledata.playing_name)+" "),(tabledata.pro_ind == 'Am')?_c('span',[_vm._v("(a)")]):_vm._e()])]):_vm._e(),(
              tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
            )?_c('td',{class:{ homeEntriesStatus: _vm.home === true}},[_vm._v(" "+_vm._s(tabledata.status_text)+" ")]):_vm._e(),(_vm.home === false)?[(
                tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
              )?_c('td',[_vm._v(" "+_vm._s(tabledata.exempt_rank)+" ")]):_vm._e()]:_vm._e(),(
              tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                _vm.config.VUE_APP_PLAYER_ICON === 'Y'
            )?_c('td',[_c('a',{attrs:{"href":'/playerprofile/' + tabledata.playerno}},[_c('font-awesome-icon',{staticClass:"icon",staticStyle:{"color":"#f2af15!important"},attrs:{"id":"user","icon":['fa', 'user']}})],1)]):_vm._e()],2)]})]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }