<template>
  <div>
    <div id="desktop" class="table-responsive marginTop">
      <b-tabs content-class="mt-3" class="tabsConent">
        <b-tab :title="'Round ' + i " v-for="(i, index) in range(1, roundsPlayed)"
          :key="index"
          variant="primary"
          class="roundBtnColour"
          @click="ShowRound(index)" active>
        </b-tab>
      </b-tabs>
      <table id="scoreCard">
        <thead>
          <tr class="t3">
            <th>
              Hole
            </th>
            <th>
              1
            </th>
            <th>
              2
            </th>
            <th>
              3
            </th>
            <th>
              4
            </th>
            <th>
              5
            </th>
            <th>
              6
            </th>
            <th>
              7
            </th>
            <th>
              8
            </th>
            <th>
              9
            </th>
            <th>
              OUT
            </th>
            <th>
              10
            </th>
            <th>
              11
            </th>
            <th>
              12
            </th>
            <th>
              13
            </th>
            <th>
              14
            </th>
            <th>
              15
            </th>
            <th>
              16
            </th>
            <th>
              17
            </th>
            <th>
              18
            </th>
            <th>
              IN
            </th>
            <th>
              TOTAL
            </th>
          </tr>
        </thead>
        <tbody >
          <template v-if="data.multi_course == 'Y'">
            <tr
              class="t3"
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <td>{{ multi.course_X_id }} Mtrs</td>
              <td
                v-for="(meter, index) in multi.course_X_length_meters
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ meter }}
              </td>
              <td>
                {{ multi.course_X_out_length.slice(1, 6) }}
              </td>
              <td
                v-for="(meter, index) in multi.course_X_length_meters
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ meter }}
              </td>
              <td>
                {{ multi.course_X_in_length.slice(1, 6) }}
              </td>
              <td>
                {{ multi.course_X_total_length.slice(1, 6) }}
              </td>
            </tr>
            <tr
              class="t3"
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <td>{{ multi.course_X_id }} Par</td>
              <td
                v-for="(par, index) in multi.course_X_par.split(',').slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ par }}
              </td>
              <td>
                {{ multi.course_X_out_par.slice(1, 6) }}
              </td>
              <td
                v-for="(par, index) in multi.course_X_par.split(',').slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ par }}
              </td>
              <td>
                {{ multi.course_X_in_par.slice(1, 6) }}
              </td>
              <td>
                {{ multi.course_X_total_par }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="t3" >
              <td>
                Yds
              </td>
              <td
                v-for="(yard, index) in data.course_length_yards
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ yard }}
              </td>
              <td>
                {{ data.course_out_length.slice(1, 6) }}
              </td>
              <td
                v-for="(number, index) in data.course_length_yards
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ number }}
              </td>
              <td>
                {{ data.course_in_length.slice(1, 6) }}
              </td>
              <td>
                {{ data.course_total_length.slice(1, 6) }}
              </td>
            </tr>
            <tr class="t3">
              <td>
                Par
              </td>
              <td
                v-for="(yard, index) in data.course_par.split(',').slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ yard }}
              </td>
              <td>
                {{ data.course_out_par.slice(1, 6) }}
              </td>
              <td
                v-for="(number, index) in data.course_par.split(',').slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ number }}
              </td>
              <td>
                {{ data.course_in_par.slice(1, 6) }}
              </td>
              <td>
                {{ data.course_total_par }}
              </td>
            </tr>
          </template>
          <template>
            <template v-if="team == 'Y' || data.bball_team == 'Y'">
              <!-- <tr class="RoundColour">
                <td>R{{ roundsPlayed }} <span v-if="data.multi_course == 'Y'" :class="playerData['course_colour_R' + roundsPlayed]">•</span></td>
                <template v-if="stablford == 'N'">
                  <td
                    v-for="(r1, index) in playerData['team_hole_scores_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['team_hole_scores_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['team_hole_scores_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['team_hole_scores_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['team_hole_scores_R' + roundsPlayed])}}</td>
                </template>
                <template v-else>
                  <td
                    v-for="(r1, index) in playerData['hole_team_points_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['hole_team_points_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['hole_team_points_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['hole_team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['hole_team_points_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['hole_team_points_R' + roundsPlayed])}}</td>
                </template>
              </tr> -->
              <!-- Partners code -->
              <tr>
                <td class="playerLeft">
                  <a :href="'/player-profiles/' + playerData.external_ref" target="_blank" class="playerLinkPartner">{{playerData.surname}}</a>
                </td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_R' + buttonRound].split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_R' + buttonRound].split(',')[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',')[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',')[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    "
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',')[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',')[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ outVal(playerData['net_hole_scores_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_R' + buttonRound].split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class=""
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ inVal(playerData['net_hole_scores_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['net_hole_scores_R' + buttonRound])}}</td>
              </tr>
              <tr>
                <td class="playerLeft">
                  <a :href="'/player-profiles/' + playerData.partner_ref_1" target="_blank" class="playerLinkPartner">{{playerData.partner_surname_1}}</a>
                </td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_1_R' + buttonRound].split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',')[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',')[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',')[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    "
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',')[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',')[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ outVal(playerData['net_hole_scores_partner_1_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_1_R' + buttonRound].split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class=""
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_1_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ inVal(playerData['net_hole_scores_partner_1_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['net_hole_scores_partner_1_R' + buttonRound])}}</td>
              </tr>
              <tr>
                <td class="playerLeft">
                  <a :href="'/player-profiles/' + playerData.partner_ref_2" target="_blank" class="playerLinkPartner" >{{playerData.partner_surname_2}}</a>
                </td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_2_R' + buttonRound].split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',')[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',')[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',')[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    "
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',')[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',')[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ outVal(playerData['net_hole_scores_partner_2_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_2_R' + buttonRound].split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class=""
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_2_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ inVal(playerData['net_hole_scores_partner_2_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['net_hole_scores_partner_2_R' + buttonRound])}}</td>
              </tr>
              <tr>
                <td class="playerLeft">
                  <a :href="'/player-profiles/' + playerData.partner_ref_3" target="_blank" class="playerLinkPartner">{{playerData.partner_surname_3}}</a>
                </td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_3_R' + buttonRound].split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',')[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',')[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',')[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    "
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',')[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',')[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ outVal(playerData['net_hole_scores_partner_3_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['net_hole_scores_partner_3_R' + buttonRound].split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class=""
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['net_hole_vspars_partner_3_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td>{{ inVal(playerData['net_hole_scores_partner_3_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['net_hole_scores_partner_3_R' + buttonRound])}}</td>
              </tr>
              <tr class="TeamRow">
                <td class="playerLeft">Team</td>
                <td
                  v-for="(r1, index) in playerData['team_hole_vspars_R' + buttonRound].split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['team_hole_vspars_R' + buttonRound].split(',')[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',')[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',')[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    "
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',')[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',')[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    <span v-if="r1 == '0'">Par</span>
                  </span>
                </td>
                <td>{{ outVal(playerData['team_hole_vspars_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span
                    class="twoUnder"
                    v-if="playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class=""
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="
                    threeover
                    "
                    v-else-if="playerData['team_hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    <span v-if="r1 == '0'">Par</span>
                  </span>
                </td>
                <td>{{ inVal(playerData['team_hole_vspars_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['team_hole_vspars_R' + buttonRound])}}</td>
              </tr>
              <!-- End of partners section -->
            </template>
            <template v-else>
              <template>
                <tr class="RoundColour">
                  <template>
                    <td>R{{ buttonRound }} <span v-if="data.multi_course == 'Y'" :class="playerData['course_colour_R' + buttonRound]">•</span></td>
                  </template>
                  <template v-if="stablford == 'N'">
                    <template>
                      <td
                        v-for="(r1, index) in playerData['hole_scores_R' + buttonRound].split(',').slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        <span 
                          v-if="(playerData['strokes_R' + buttonRound] || '').split(',')[0] == (index + 1)" 
                          class="roundStroke"
                        >{{ (playerData['strokes_R' + buttonRound] || '').split(',')[1] }}</span>
                        <span
                          class="twoUnder"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] == -2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneUnder"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] == 2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="
                          threeover
                          "
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] == -3"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',')[index] > 2"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                      <td>{{ outVal(playerData['hole_scores_R' + buttonRound]) }}</td>
                      <td
                        v-for="(r1, index) in playerData['hole_scores_R' + buttonRound].split(',').slice(9, 18)"

                        :key="index + Math.random()"
                      >
                        <span 
                          v-if="(playerData['strokes_R' + buttonRound] || '').split(',')[0] == (index + 10)" 
                          class="roundStroke"
                        >{{ (playerData['strokes_R' + buttonRound] || '').split(',')[1] }}</span>
                        <span
                          class="twoUnder"
                          v-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneUnder"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] > 2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="
                          threeover
                          "
                          v-else-if="playerData['hole_vspars_R' + buttonRound].split(',').slice(9, 18)[index] == -3"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                      <td>{{ inVal(playerData['hole_scores_R' + buttonRound]) }}</td>
                      <td>{{ totalVal(playerData['hole_scores_R' + buttonRound])}}</td>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="playerData['gir_R' + roundsPlayed] !== undefined">
                      <td
                        v-for="(r1, index) in playerData['hole_points_R' + roundsPlayed].split(',').slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        <span
                          class="twoUnder"
                          v-if="playerData['hole_vspars_R' + roundsPlayed].split(',')[index] == -2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneUnder"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',')[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',')[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',')[index] > 2"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                      <td>{{ outVal(playerData['hole_points_R' + roundsPlayed]) }}</td>
                      <td
                        v-for="(r1, index) in playerData['hole_points_R' + roundsPlayed].split(',').slice(9, 18)"

                        :key="index + Math.random()"
                      >
                        <span
                          class="twoUnder"
                          v-if="playerData['hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneUnder"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerData['hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] > 2"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                      <td>{{ inVal(playerData['hole_points_R' + roundsPlayed]) }}</td>
                      <td>{{ totalVal(playerData['hole_points_R' + roundsPlayed])}}</td>
                    </template>
                  </template>
                </tr>
              </template>
            </template>
            <template v-if="playerData['gir_R' + buttonRound] ">
              <tr :key="roundIt1" :class="{removeTR : outVal(playerData['drive_R' + buttonRound]) == 0 && inVal(playerData['drive_R' + buttonRound]) == 0 && totalVal(playerData['drive_R' + buttonRound]) == 0}">
                <td>D/D</td>
                <td
                  v-for="(r1, index) in playerData['drive_R' + buttonRound]
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                >
                  {{ r1 }}
                </td>
                <td>{{ outVal(playerData['drive_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['drive_R' + buttonRound]
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                >
                  {{ r1 }}
                </td>
                <td>{{ inVal(playerData['drive_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['drive_R' + buttonRound])}}</td>
              </tr>
              <tr :key="roundIt1" :class="{removeTR : outVal(playerData['fairway_R' + buttonRound]) == 0 && inVal(playerData['fairway_R' + buttonRound]) == 0 && totalVal(playerData['fairway_R' + buttonRound]) == 0}">
                <td>F/W</td>
                <td
                  v-for="(r1, index) in playerData['fairway_R' + buttonRound]
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['fairway_R' + buttonRound], 'front') }}</td>
                <td
                  v-for="(r1, index) in playerData['fairway_R' + buttonRound]
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['fairway_R' + buttonRound], 'back') }}</td>
                <td>{{ StatoutVal(playerData['fairway_R' + buttonRound], 'total')}}</td>
              </tr>
              <tr :class="{removeTR : outVal(playerData['gir_R' + buttonRound]) == 0 && inVal(playerData['gir_R' + buttonRound]) == 0 && totalVal(playerData['gir_R' + buttonRound]) == 0}">
                <td>GiR</td>
                <td
                  v-for="(r1, index) in playerData['gir_R' + buttonRound]
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['gir_R' + buttonRound], 'front') }}</td>
                <td
                  v-for="(r1, index) in playerData['gir_R' + buttonRound]
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['gir_R' + buttonRound], 'back') }}</td>
                <td>{{ StatoutVal(playerData['gir_R' + buttonRound], 'total')}}</td>
              </tr>
              <tr :key="roundIt1" :class="{removeTR : outVal(playerData['sand_R' + buttonRound]) == 0 && inVal(playerData['sand_R' + buttonRound]) == 0 && totalVal(playerData['sand_R' + buttonRound]) == 0}">
                <td>S/S</td>
                <td
                  v-for="(r1, index) in playerData['sand_R' + buttonRound]
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['sand_R' + buttonRound], 'front') }}</td>
                <td
                  v-for="(r1, index) in playerData['sand_R' + buttonRound]
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                >
                  <span v-if="r1 === 'Y' || r1 === 'y'"
                    ><font-awesome-icon class="check" :icon="['fa', 'check']"
                  /></span>
                  <span v-else-if="r1 === 'N' || r1 === 'n'"
                    ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                  /></span>
                  <span v-else>{{ r1 }}</span>
                </td>
                <td>{{ StatoutVal(playerData['sand_R' + buttonRound], 'back') }}</td>
                <td>{{ StatoutVal(playerData['sand_R' + buttonRound], 'total')}}</td>
              </tr>
              <tr :key="roundIt1" :class="{removeTR : outVal(playerData['putts_R' + buttonRound]) == 0 && inVal(playerData['putts_R' + buttonRound]) == 0 && totalVal(playerData['putts_R' + buttonRound]) == 0}">
                <td>Putts</td>
                <td
                  v-for="(r1, index) in playerData['putts_R' + buttonRound]
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                >
                  {{ r1 }}
                </td>
                <td>{{ outVal(playerData['putts_R' + buttonRound]) }}</td>
                <td
                  v-for="(r1, index) in playerData['putts_R' + buttonRound]
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                >
                  {{ r1 }}
                </td>
                <td>{{ inVal(playerData['putts_R' + buttonRound]) }}</td>
                <td>{{ totalVal(playerData['putts_R' + buttonRound])}}</td>
              </tr>
            </template>
            <template v-else>

            </template>
          </template>
        </tbody>
      </table>

    <!-- Strokes Gained Section -->
      <template v-if="data.hide_sg_sc == 'N'">
        <h3 class="strokesGainedTitle" 
          v-if="playerData['sg_total_R' + buttonRound] !== '' ||
          playerData['sg_drive_R' + buttonRound] !== '' ||
          playerData['sg_approach_R' + buttonRound] !== '' ||
          playerData['sg_short_R' + buttonRound] !== '' ||
          playerData['sg_putting_R' + buttonRound] !== ''
        "
        >Strokes Gained</h3>
        <table id="scoreCard" 
          v-if="playerData['sg_total_R' + buttonRound] !== '' ||
          playerData['sg_drive_R' + buttonRound] !== '' ||
          playerData['sg_approach_R' + buttonRound] !== '' ||
          playerData['sg_short_R' + buttonRound] !== '' ||
          playerData['sg_putting_R' + buttonRound] !== ''
        ">
          <thead>
            <tr class="t3">
              <!-- <th class="titleWidth">
                Round
              </th> -->
              <th class="titleWidth">
                Driving
              </th>
              <th class="titleWidth">
                Approach
              </th>
              <th class="titleWidth">
                Short Game
              </th>
              <th class="titleWidth">
                Putting
              </th>
              <th class="titleWidth">
                Total
              </th>
            </tr>
          </thead>
          <tbody >
            <template>
              <!-- <template v-if="team == 'Y' || data.bball_team == 'Y'">
                <tr>
                  <td>
                    <a :href="'/player-profiles/' + playerData.external_ref" target="_blank" class="playerLinkPartner">{{playerData.surname}}</a>
                  </td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['net_hole_scores_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['net_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['net_hole_scores_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['net_hole_scores_R' + roundsPlayed])}}</td>
                </tr>
                <tr>
                  <td>
                    <a :href="'/player-profiles/' + playerData.partner_ref_1" target="_blank" class="playerLinkPartner">{{playerData.partner_surname_1}}</a>
                  </td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_1_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['net_hole_scores_partner_1_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_1_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['net_hole_vspars_partner_1_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['net_hole_scores_partner_1_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['net_hole_scores_partner_1_R' + roundsPlayed])}}</td>
                </tr>
                <tr>
                  <td>
                    <a :href="'/player-profiles/' + playerData.partner_ref_2" target="_blank" class="playerLinkPartner" >{{playerData.partner_surname_2}}</a>
                  </td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_2_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['net_hole_scores_partner_2_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_2_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['net_hole_vspars_partner_2_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['net_hole_scores_partner_2_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['net_hole_scores_partner_2_R' + roundsPlayed])}}</td>
                </tr>
                <tr>
                  <td>
                    <a :href="'/player-profiles/' + playerData.partner_ref_3" target="_blank" class="playerLinkPartner">{{playerData.partner_surname_3}}</a>
                  </td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_3_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerData['net_hole_scores_partner_3_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['net_hole_scores_partner_3_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['net_hole_vspars_partner_3_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerData['net_hole_scores_partner_3_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['net_hole_scores_partner_3_R' + roundsPlayed])}}</td>
                </tr>
                <tr class="TeamRow">
                  <td>Team</td>
                  <td
                    v-for="(r1, index) in playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      <span v-if="r1 == '0'">Par</span>
                    </span>
                  </td>
                  <td>{{ outVal(playerData['team_hole_vspars_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerData['team_hole_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      <span v-if="r1 == '0'">Par</span>
                    </span>
                  </td>
                  <td>{{ inVal(playerData['team_hole_vspars_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerData['team_hole_vspars_R' + roundsPlayed])}}</td>
                </tr>
              </template> -->
              <template>
                <template>
                  <tr class="RoundColour">
                    <!-- <template>
                      <td>R{{ buttonRound }} <span v-if="data.multi_course == 'Y'" :class="playerData['course_colour_R' + buttonRound]">•</span></td>
                    </template> -->
                    <template>
                      <template>
                        <td>{{playerData['sg_drive_R' + buttonRound]}}</td>
                        <td>{{playerData['sg_approach_R' + buttonRound]}}</td>
                        <td>{{playerData['sg_short_R' + buttonRound]}}</td>
                        <td>{{playerData['sg_putting_R' + buttonRound]}}</td>
                        <td>{{playerData['sg_total_R' + buttonRound]}}</td>
                      </template>
                    </template>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </template>

    </div>





    <div id="mobile">
        <b-tabs content-class="mt-3">
          <b-tab title="Front" active>
            <table id="scoreCard">
              <thead>
                <tr class="blue">
                  <th>
                    Hole
                  </th>
                  <th>
                    1
                  </th>
                  <th>
                    2
                  </th>
                  <th>
                    3
                  </th>
                  <th>
                    4
                  </th>
                  <th>
                    5
                  </th>
                  <th>
                    6
                  </th>
                  <th>
                    7
                  </th>
                  <th>
                    8
                  </th>
                  <th>
                    9
                  </th>
                  <th>
                    OUT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="blue">
                  <td>
                    Yds
                  </td>
                  <td
                    v-for="(yard, index) in data.course_length_yards
                      .split(',')
                      .slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    {{ yard }}
                  </td>
                  <td> 
                    {{ data.course_out_length.slice(1, 6) }}
                  </td>
                </tr>
                <tr class="blue">
                  <td>
                    Par
                  </td>
                  <td
                    v-for="(yard, index) in data.course_par
                      .split(',')
                      .slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    {{ yard }}
                  </td>
                  <td>
                    {{ data.course_out_par.slice(1, 6) }}
                  </td>
                </tr>
                <template v-if="stablford === 'N'">
                  <tr
                    v-for="(i, roundIt1) in range(1, roundsPlayed)"
                    :key="roundIt1"
                  >
                    <td>R{{ i }}</td>
                    <td
                      v-for="(r1, index) in playerData['hole_scores_R' + i]
                        .split(',')
                        .slice(0, 10)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerData['hole_vspars_R' + i].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="threeUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -3"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] > 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>                
                  <tr
                    v-for="(i, roundIt1) in range(1, roundsPlayed)"
                    :key="roundIt1"
                  >
                    <td>R{{ i }}</td>
                    <td
                      v-for="(r1, index) in playerData['hole_points_R' + i]
                        .split(',')
                        .slice(0, 10)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerData['hole_vspars_R' + i].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="threeUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -3"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                  </tr>
                </template>

              </tbody>
            </table>
          </b-tab>
          <b-tab title="Back">
            <table id="scoreCard">
              <thead>
                <tr class="blue">
                  <th>
                    Hole
                  </th>
                  <th>
                    10
                  </th>
                  <th>
                    11
                  </th>
                  <th>
                    12
                  </th>
                  <th>
                    13
                  </th>
                  <th>
                    14
                  </th>
                  <th>
                    15
                  </th>
                  <th>
                    16
                  </th>
                  <th>
                    17
                  </th>
                  <th>
                    18
                  </th>
                  <th>
                    IN
                  </th>
                  <th>
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="blue">
                  <td>
                    Yds
                  </td>
                  <td
                    v-for="(number, index) in data.course_length_yards
                      .split(',')
                      .slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ data.course_in_length.slice(1, 6) }}
                  </td>
                  <td>
                    {{ data.course_total_length.slice(1, 6) }}
                  </td>
                </tr>
                <tr class="blue">
                  <td>
                    Par
                  </td>
                  <td
                    v-for="(number, index) in data.course_par
                      .split(',')
                      .slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ data.course_in_par.slice(1, 6) }}
                  </td>
                  <td>
                    {{ data.course_total_par.slice(1, 6) }}
                  </td>
                </tr>
                <template v-if="stablford === 'N'">
                  <tr
                    v-for="(i, roundIt1) in range(1, roundsPlayed)"
                    :key="roundIt1"
                  >
                    <td>R{{ i }}</td>
                    <td
                      v-for="(r1, index) in playerData['hole_scores_R' + i]
                        .split(',')
                        .slice(10, 21)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerData['hole_vspars_R' + i].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="threeUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -3"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(i, roundIt1) in range(1, roundsPlayed)"
                    :key="roundIt1"
                  >
                    <td>R{{ i }}</td>
                    <td
                      v-for="(r1, index) in playerData['hole_points_R' + i]
                        .split(',')
                        .slice(10, 21)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerData['hole_vspars_R' + i].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="threeUnder"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -3"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerData['vspar_R' + i].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "playerReportCard",
  props: ["data", "roundsPlayed", "stablford", "team", "courses", "playerData", "playerDataTeam", "develop", "rts"],
  data() {
    return {
      oneUnder: "oneUnder",
      twoUnder: "twoUnder",
      threeUnder: "threeUnder",
      oneOver: "oneOver",
      twoOver: "twoOver",
      buttonRound: this.roundsPlayed,
      isActive: '',
    };
  },
  methods: {
    ShowRound(index) {
      console.log("index")
      console.log(index)
      this.buttonRound = this.roundsPlayed
      return this.buttonRound = index +1
    },
    // ShowRound(index) {
    //   console.log("index")
    //   console.log(index)
    //   if (index == '1') {
    //     console.log('one')
    //   } else if(index == '2') {
    //     console.log('two')   
    //   } else if(index == '3') {
    //     console.log('three')
    //   } else if(index == '4') {
    //     console.log('four')
    //   } else if(index == '5') {
    //     console.log('five')
    //   } else{
    //     console.log('else')
    //   }
    // },
    // sum: function(nums) {
    //   let result = 0;
    //   nums.forEach(function(n) {
    //     result += n * 1;
    //   });
    //   if (result == 0)
    //       return result;
    //   else if (result > 0)
    //       return result;
    //   else if (result < 0)
    //     return result;

    //   return result;
    // },
    StatoutVal(value, inOut) {
      // var split = value.split(",");
      // var front9 = split.slice(0, 9);
      var full = value.split(',')
      var fullCheck = full.filter((str) => str !== '' && str !== 'N');
      var fullTotal = full.filter((str) => str !== '')
      var front9Full = full.slice(0,9)
      var back9Full = full.slice(9,18)
      var front9Check = front9Full.filter((str) => str !== '' && str !== 'N');
      var frontTotal = front9Full.filter((str) => str !== '')
      var back9Check = back9Full.filter((str) => str !== '' && str !== 'N');
      var backTotal = back9Full.filter((str) => str !== '')
      if(inOut === 'front'){
        var total = front9Check.length + '/' + frontTotal.length
      }
      else if(inOut === 'back'){
        total = back9Check.length + '/' + backTotal.length
      }
      else{
        total = fullCheck.length + '/' + fullTotal.length
      }
      return total;
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    getArraySum: function(array, f, t, zeroVal) 
    {
      var result = 0;

      if (Array.isArray(array))
      {
        if (!f) f = 0;
        if (!t) t = (array.length - 1);

        for (var i = f; i <= t; i++)
        {
          var arrVal = array[i];

          if (arrVal != "" && !isNaN(arrVal))
            result += parseInt(arrVal);
        }
      }

      if (result == 0 && typeof zeroVal == "string")
        result = zeroVal;

      return result;
    }
  },
  mounted() {
    this.getArraySum();
  }
};
</script>

<style scoped>
.threeover {
  color: #fff;
  background: #fbbc04;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
td.playerLeft {
  text-align: left;
  padding-left: 0px;
}
.titleWidth {
 /* width: 16.6666666667%; */
 width: 20%;
}
.roundStroke {
  color: #3c9933 !important;
  font-weight: bold;
  font-style: italic; 
  font-size: 12px;
  background-color: transparent;
}
.strokesGainedTitle {
  color: #133f7b;
  font-size: 1rem;
  margin-top: 30px;
}
::v-deep tr.removeTR {
  display: none;
}
::v-deep tr.TeamRow {
  background: #ebe9e9!important;
}
::v-deep tr.TeamRow:hover {
  background: #ebe9e9!important;
}
a.playerLinkPartner {
  text-decoration: none;
  color: #212529;
  cursor: pointer;
  text-align: left;
}
::v-deep .tabsConent {
  margin-left: 123.5px;
  margin-bottom: 0em;
}
.active {
  background: #01477b !important;
  border-color: #01477b;
}
.btn-group.btn-group-sm {
  margin-left: 10%;
  float: left;
}
.roundBtnColour {
  background-color: #015699;
  border-color: #015699;
  border-radius: 0px;
}
tr.RoundColour.nonRound:nth-child(odd) {
  background-color: #fff!important;
  color: #000!important;
}
tr.RoundColour.nonRound:nth-child(even) {
  background-color: rgba(0,0,0,.03)!important;
  color: #000!important;
}
.black {
  color: #000;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
div#desktop {
  margin-top: 1rem;
}
tr.RoundColour {
  /* background-color: #b7d8fb!important; */
}
#scoreCard {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  font-size: 14px;
}
th {
  padding: 5px;
  font-weight: 400;
}
td {
  padding: 5px;
}
#mobile {
  display: none;
}
.oneOver {
  color: #fff;
  background: #1e90ff;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.twoOver {
  color: #fff;
  background: #e07020;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.oneUnder {
  color: #fff;
  background: #a52a2a;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.twoUnder {
  color: #fff;
  background: #3c9933;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.threeUnder {
  color: #fff;
  background: #fbbc04;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.t3 {
  background-color: rgb(51, 203, 169) !important;
  color: white;
}
::v-deep th:first-child {
  /* border-top-left-radius: 15px; */
}
::v-deep th:last-child {
  /* border-top-right-radius: 15px; */
}
tr:nth-child(even) {
/* background-color: rgba(0,0,0,.03); */
}
tr:nth-child(odd) {
  background-color: white;
}
.Brow {
  background-color: #333333 !important;
}
.Rrow {
  background-color: #bb0000 !important;
}
.B {
  color: #333333 !important;
  font-size: 40px !important;
  line-height: 25px !important;
  vertical-align: bottom;
}
.R {
  color: #bb0000 !important;
  font-size: 40px !important;
  line-height: 25px !important;
  vertical-align: bottom;
}
.check {
  color: green;
}
.cross {
  color: darkred;
}
@media only screen and (max-width: 500px) {
  ::v-deep .nav-tabs  > li.nav-item + li.nav-item {
    display: block!important;
  }
  ::v-deep .nav-tabs  > li.nav-item {
    width: 100%!important;
  }
}
@media only screen and (max-width: 480px) {
  #scoreCard {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  #desktop {
    display: none;
  }
  #mobile {
    display: block;
  }
  ::v-deep .mt-3 {
    margin-top: 0 !important;
  }
  ::v-deep .nav-tabs .nav-link.active {
    color: white;
    background-color: #263056;
    text-align: center;
    width: 100%;
    border-color: #263056;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  ::v-deep .nav-tabs .nav-link {
    text-align: center;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  ::v-deep .nav-link {
    color: black;
    width: 50%;
  }
  ::v-deep .nav-item {
    width: 50%;
  }
  .blue {
    background-color: #133f7b !important;
    color: white;
  }
}
</style>