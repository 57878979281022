<template>
  <div class="home">
    <b-container class="homeContainer">
        <b-row>
          <b-col lg='12' md='12' cols='12' class="mobileWidth">
              <div class="homeReport-list HomehomeReport-list homeHeight" :style="'height:' + NewWidgetHeight" :class="{mobWidgetPad: mobWidget == 'Y'}">
                <template v-if="showReports == 'Y'">
                  <b-tabs content-class="test" >
                    <b-tab title="This Week" id="onetab" title-item-class="tabsStyle tabone" active>
                      <!-- <b-form-select v-model="class_api" class="textPos"  v-on:change="changeTournament(class_api)" v-if="show == true">  -->
                      <div class="TournDrop" v-if="show == true">
                      <template v-if="multi_tourns == 'Y'">
                        <b-form-select v-model="code" class="TourndropdownArea"  @change="swapTour(code)" > 
                          <b-form-select-option 
                            class="opt-arrow"
                            v-for="(past,
                            index) in listFinalAll"
                            :key="index" 
                            :value="past.code"
                          >
                            {{ past.name }}
                          </b-form-select-option>
                        </b-form-select>
                      </template>
                      <template v-else>
                        <b-form-select v-model="class_api" class="TourndropdownArea"  @change="changeTournament(class_api)" > 
                          <b-form-select-option 
                            class="opt-arrow"
                            v-for="(value,
                            index) in FullListT"
                            :key="index"
                            :value="value.class_api"
                          >
                            {{value.short_name}}
                          </b-form-select-option>
                        </b-form-select>
                      </template>
                      </div>
                      <template v-if="this.runData == 'Y'">
                          <!-- Change Tourn  -->
                          <ReportHomeTab
                            :data="data"
                            :Year="season"
                            :code="course"
                            :course_code="course_code"
                            :reportURL="currentReport"
                            :currentReport="reportTitle"
                            :title="currentTitle"
                            :config="config"
                            :home="home"
                            :tournDates="tournDates"
                            :updateSize="updateSize"
                            :widgetHeight="widgetHeight"
                            :reports="reports"
                            :tourCode="tourCode"
                            :show="show"
                            :runData="runData"
                          />
                          <div class="LiveScoreMessage" v-if="tm_params.live_message == 'Y'">
                            <p v-html="tm_params.live_scoring_mess"></p>
                          </div>
                      </template>
                      <template v-else>
                        <!-- Orginal Tourn -->
                        <ReportHomeTab
                          :data="data"
                          :Year="season"
                          :code="course"
                          :course_code="course_code"
                          :reportURL="currentReport"
                          :currentReport="reportTitle"
                          :title="currentTitle"
                          :config="config"
                          :home="home"
                          :tournDates="tournDates"
                          :updateSize="updateSize"
                          :widgetHeight="widgetHeight"
                          :reports="reports"
                          :tourCode="tourCode"
                          :show="show"
                          :runData="runData"
                        />
                          <div class="LiveScoreMessage" v-if="tm_params.live_message == 'Y'">
                            <p v-html="tm_params.live_scoring_mess"></p>
                          </div>
                      </template>
                    </b-tab>
                    <b-tab title="Upcoming" title-item-class="tabsStyle tabtwo" classActive>
                      <UpcomingReportSelect :season="season"/>
                    </b-tab>
                    <b-tab title="Results" title-item-class="tabsStyle tabthree">
                      <ResultsReportSelect :season="season"/>
                    </b-tab>
                  </b-tabs>
                </template>
                <template v-else>
                  <b-tabs content-class="test" >
                    <!-- <b-tab title="This Week" id="onetab" title-item-class="tabsStyle tabone">
                      <ReportHomeTab
                        :Year="season"
                        :code="course"
                        :course_code="course_code"
                        :reportURL="currentReport"
                        :currentReport="reportTitle"
                        :title="currentTitle"
                        :config="config"
                        :home="home"
                        :tournDates="tournDates"
                        :updateSize="updateSize"
                        :widgetHeight="widgetHeight"
                        :reports="reports"
                      />
                    </b-tab> -->
                    <b-tab title="Upcoming" title-item-class="tabsStyle tabWidth" classActive active>
                      <UpcomingReportSelect :season="season"/>
                    </b-tab>
                    <b-tab title="Results" title-item-class="tabsStyle tabWidth">
                      <ResultsReportSelect :season="season"/>
                    </b-tab>
                  </b-tabs>
                </template>
              </div>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportHomeTab from "@/components/reportHometab.vue";
import UpcomingReportSelect from "@/components/UpcomingReportSelect.vue";
import ResultsReportSelect from "@/components/ResultsReportSelect.vue";
import axios from "axios";
export default {
  name: "home", 
  props: ['config'],
  components: { 
    UpcomingReportSelect,
    ResultsReportSelect,
    ReportHomeTab,
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      title: [], //Page Title
      description: [], //Meta Description
      homepage: true,
      home: true,
      wphome: [],
      inter: "5",
      pro: "44",
      latestsm: "29",
      latestFull: "8",
      featHome: "true",
      reports: [],
      course: [],
      season: [],
      course_code: [],
      tm_params:[],
      mobWidget: process.env.VUE_APP_MOB_WIDGET,
      tourCode: '',
      text: '',
      NewWidgetHeight: '',
      activeTab: '',
      scores_available: '',
      daysAway: '',
      showReports: '',
      results_available: '',
      smallListTours: [],
      FullListT: [],
      show: false,
      runData: 'N',
      url: '',
      multi_tourns: '',
      finalFilter: [],
      firstArray: [],
      listFinalAll:[],
      EveryTour: [],
    };
  },
  methods: {
    updateStuffMulti: function() {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
            'aus/' +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              this.tm_params.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.tm_params = response.data;
        });
    },
    swapTour:function(code) {
      this.course = code;
      console.log("this.course -- in swap")
      console.log(this.course)
      this.updateStuffMulti();
      this.getSecondTournTicx(code);
    },
    getSecondTournTicx(code) {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE + 
              'aus/' +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              code +
              "-tmticx.json?mattrandomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.second_tourn_tixc = response.data;
          this.winnerType = response.data.winner_result;
          if (typeof this.second_tourn_tixc.reports != "undefined") {
            this.second_tourn_tixc.reports.reports_entry.forEach(element => {
              if (element.report_url.indexOf("tmscore") !== -1) {
                this.report = element.report_url;
                this.updateStuffMulti();
              }
              if (element.report_url.indexOf("tmresult") !== -1) {
                this.report = element.report_url;
                this.updateStuffMulti();
              }
            });
          }
        });
    },



    reportListText: function() {
      console.log('InFunction')
      if (Array.isArray(this.reports)) {
        console.log('not array')
        this.activeTab = false
        this.classActive = 'active'
      } else {
        this.reports.forEach((value) => {
          console.log('is array')
          console.log('In For Each Function')
          if (value.report_title.includes('Score')) {
            this.activeTab = true
            this.classActive = 'active'
            console.log('TRUE')
          } else {
            this.activeTab = false
            this.classActive = ''
            console.log('FALSE')
          }
        });
      } 
    },
    myFunction: function() {
      let h = window.outerWidth;
      let txt = h;
      this.text = txt;
      if (this.text == 1400 || this.text > 1401) {
        this.NewWidgetHeight = '669px!important'
      } else if(this.text < 1400  && this.text > 1199) {
        this.NewWidgetHeight = '555px!important'
      } else if(this.text > 990 && this.text < 1199) {
        this.NewWidgetHeight = '492px!important'
      }
    },
    getAllTourns: function() {
      // var ausTours = sessionStorage.getItem("aus");
      // var snrTours = JSON.parse(sessionStorage.getItem("snr"));
      // var allTours = JSON.parse(sessionStorage.getItem("all"));
      var ausTours = JSON.parse(sessionStorage.getItem("aus"));
      var wpgTours = JSON.parse(sessionStorage.getItem("wpg"));
      if (wpgTours != '') {
         this.EveryTour = ausTours.concat(wpgTours);
      } else {
         this.EveryTour = ausTours;
      }
      // var EveryTour = ausTours.concat(wpgTours);
      // var EveryTour = ausTours.concat(wpgTours,snrTours, allTours);

      let smallListTours = [];

      this.EveryTour.forEach((value) => {
        if (value.days_away < 5 && value.ts_final_result == 'N') {
          smallListTours.push(value);
        }
      }); 
      smallListTours.sort((a, b) => {
          let da = a.days_away,
              db = b.days_away;
          return da - db; 
      });

      console.log("New smallListTours");
      console.log(smallListTours);
      this.smallListTours = smallListTours;


      // Filter Section to ssee if days away match

      //Order index 0 postion
      if (smallListTours[0].class_api == 'aus') {
        console.log('In first Position is Aus')
        this.tourCode = 'aus';
        this.gettmticXData(this.tourCode)
      } else if (smallListTours[0].class_api == 'wpg')  {
        console.log('In first Position is wpg')
        this.tourCode = 'wpg';
        this.gettmticXData(this.tourCode)
      }
      // } else if (smallListTours[0].class_api == 'snr')  {
      //   console.log('In first Position is snr')
      //   this.tourCode = 'snr';
      //   this.gettmticXData(this.tourCode)
      // }  else if (smallListTours[0].class_api !== 'wpg' || smallListTours[0].class_api !== 'aus' || smallListTours[0].class_api !== 'snr')  {
      //   console.log('In first Position is all')
      //   this.tourCode = 'all';
      //   this.gettmticXData(this.tourCode)
      // }

    },
    gettmticXData: function() {
      axios 
      .get('https://wp-pgaaus.ocs-sport.com/api/pgaaus/cache/' + this.tourCode + '/tmticx?randomadd=' + new Date().getTime())
      .then(({ data }) => {
        this.tm_params = data;
        this.reports = data.reports.reports_entry;
        this.reportsStart = data.reports;
        if (this.reportsStart != '') {
          let arr = [];
          this.results_available = arr;
          this.reports.forEach((value) => {
              if (value.report_title.includes('Result')) {
                arr.push(value.report_title);
                // console.log(value); 
              }
          }); 
        }
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.multi_tourns = data.multi_tourns;
        this.name = data.short_name
        this.course_code = data.course_code;
        this.tournDates = data.dic_dates;
        this.scores_available = data.scores_available;
        this.ts_round_status_1 = data.ts_round_status_1;
        this.daysAway = parseInt(data.days_away);
        this.firstArray = [
          {
            'name': this.name,
            'code': this.course,
          }
        ]
        this.pastTourns = data.past_tourns;
        this.futureTourns = data.future_tourns;
        if (this.pastTourns != '') {
          if (Array.isArray(data.past_tourns.past_tourns_entry)) {
            console.log("isArray");
            this.past_tourns = data.past_tourns.past_tourns_entry;
          } else {
            console.log("is not Array");
            this.past_tourns = [data.past_tourns.past_tourns_entry];
          }
          var listFinal =  this.firstArray.concat(this.past_tourns);
          this.listFinalAll = listFinal;          
        } else if (this.futureTourns !== '') {
          if (Array.isArray(data.future_tourns.future_tourns_entry)) {
            console.log("isArray");
            this.past_tourns = data.future_tourns.future_tourns_entry;
          } else {
            console.log("is not Array");
            this.past_tourns = [data.future_tourns.future_tourns_entry];
          }
          var listFinals=  this.firstArray.concat(this.past_tourns);
          this.listFinalAll = listFinals;
        }

        // if (this.daysAway > 5 || this.results_available != '' || this.ts_round_status_1 != 'S') {
        // if (this.daysAway > 5 && this.results_available != '' && this.ts_round_status_1 != 'S' || this.ts_round_status_1 != 'C') {
        if (this.daysAway > 5) {
          this.showReports = 'N'
        } else {
          this.showReports = 'Y'
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
    changeTournament: function(class_api) {
      this.tourCode = class_api;
      console.log("this.tourCode")
      console.log(this.tourCode)
      console.log('Change')
      this.runData = 'Y'
      console.log("this.runData -- Homepage")
      console.log(this.runData)
      axios 
      .get('https://wp-pgaaus.ocs-sport.com/api/pgaaus/cache/' + this.tourCode + '/tmticx?randomadd=' + new Date().getTime())
      .then(({ data }) => {
        this.tm_params = data;
        this.reports = data.reports.reports_entry;
        this.reportsStart = data.reports;
        if (this.reportsStart != '') {
          let arr = [];
          this.results_available = arr;
          this.reports.forEach((value) => {
              if (value.report_title.includes('Result')) {
                arr.push(value.report_title);
                // console.log(value); 
              }
          }); 
        }
        this.season = data.tm_params.season_code;
        this.course = data.code;
        console.log("this.course")
        console.log(this.course)
        this.course_code = data.course_code;
        this.tournDates = data.dic_dates;
        this.scores_available = data.scores_available;
        this.ts_round_status_1 = data.ts_round_status_1;
        this.daysAway = parseInt(data.days_away);
        this.updateStuff()
        // if (this.daysAway > 5 || this.results_available != '' || this.ts_round_status_1 != 'S') {
        // if (this.daysAway > 5 && this.results_available != '') {
        if (this.daysAway > 5) {
          this.showReports = 'N'
        } else {
          this.showReports = 'Y'
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
    changeTournamentMulti: function(code) {
      this.tourCode = 'aus';
      this.course = code
      console.log('Change')
      this.runData = 'Y'
      console.log("this.runData -- Homepage")
      console.log(this.runData)
      axios 
      .get('https://wp-pgaaus.ocs-sport.com/api/pgaaus/cache/' + this.tourCode + '/tmticx?randomadd=' + new Date().getTime())
      .then(({ data }) => {
        this.tm_params = data;
        this.reports = data.reports.reports_entry;
        this.reportsStart = data.reports;
        if (this.reportsStart != '') {
          let arr = [];
          this.results_available = arr;
          this.reports.forEach((value) => {
              if (value.report_title.includes('Result')) {
                arr.push(value.report_title);
                // console.log(value); 
              }
          }); 
        }
        this.season = data.tm_params.season_code;
        // this.course = data.code;
        console.log("this.course")
        console.log(this.course)
        this.course_code = data.course_code;
        this.tournDates = data.dic_dates;
        this.scores_available = data.scores_available;
        this.ts_round_status_1 = data.ts_round_status_1;
        this.daysAway = parseInt(data.days_away);
        this.updateStuff()
        // if (this.daysAway > 5 || this.results_available != '' || this.ts_round_status_1 != 'S') {
        // if (this.daysAway > 5 && this.results_available != '') {
        if (this.daysAway > 5) {
          this.showReports = 'N'
        } else {
          this.showReports = 'Y'
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
    FilterFinalTourns: function() {
      if (this.filterTournaments.length > 1) {
        this.FullListT = this.filterTournaments;
        let chars = this.FullListT;

        let uniqueChars = [];
        this.finalFilter = uniqueChars;
        console.log("this.finalFilter")
        console.log(this.finalFilter)
        chars.forEach((element) => {
            if (!uniqueChars.includes(element.code)) {
                uniqueChars.push({ name: element.short_name, code: element.code, });
            }
        });

        console.log("uniqueChars");
        console.log(uniqueChars);
        console.log('this.FullListT')
        console.log(this.FullListT)
        this.show = true;
      } else if(this.filterTournamentsOne.length > 1) {
        this.FullListT = this.filterTournamentsOne;
      } else if(this.filterTournamentsTwo.length > 1) {
        this.FullListT = this.filterTournamentsTwo;
        // this.show = true;
      } else if(this.filterTournamentsThree.length > 1) {
        this.FullListT = this.filterTournamentsThree;
      } else if(this.filterTournamentsFour.length > 1) {
        this.FullListT = this.filterTournamentsFour;
      } else {
        console.log('false')
      }
    },
    async updateStuff() {
      try {
        // First call, await for call to return
        // let first_call = await apiCall.tmParamsYear(this.season);
        // this.tm_params = first_call.data;
        // this.loading = false;
        // Second call, await for call to return

        if (this.multiTourns == "Y") {
          let first_call = await apiCall.report(this.reportTitle);
          const ticx_url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-tmticx.json?randomadd=" +
            new Date().getTime();
          let ticx_call = await apiCall.report(ticx_url);
          this.data = first_call.data;

          if (ticx_call && ticx_call.data.reports) {
            const reports = ticx_call.data.reports.reports_entry;
            if (!Array.isArray(reports)) {
              console.log('isNot Array')
            } else{
              reports.forEach(element => {
                if (element.report_url.indexOf("tmentry") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                  // this.updateStuff();
                }
                if (element.report_url.indexOf("tmscore") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                }
                if (element.report_url.indexOf("tmresult") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                  // this.updateStuff();
                }
              });
            }
          }
        } else {
          let first_call = await apiCall.report(this.reportTitle);
          this.data = first_call.data;
          this.loading = false;
        }
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    getReports: function(currentReport) {
      console.log('in reports')
      apiCall
      .report(currentReport)
      .then(({ data }) => {
        this.data = data;
        console.log("this.data")
        console.log(this.data)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
  },
  mounted() {
    this.getAllTourns();
    this.FilterFinalTourns()
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/202?randomadd=" +
          new Date().getTime()
      )
      .then((response) => (
        this.wphome = response.data
      ));
  },
  computed: {
    filterTournaments: function() {
      return this.smallListTours.filter(
        value =>
          value.days_away == '0',
      );
    },
    filterTournamentsOne: function() {
      return this.smallListTours.filter(
        value =>
          value.days_away == '1',
      );
    },
    filterTournamentsTwo: function() {
      return this.smallListTours.filter(
        value =>
          value.days_away == '2',
      );
    },
    filterTournamentsThree: function() {
      return this.smallListTours.filter(
        value =>
          value.days_away == '3'
      );
    },
    filterTournamentsFour: function() {
      return this.smallListTours.filter(
        value =>
          value.days_away == '4'
      );
    },
    
    filterReport: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            // reports_entry.report_title == 'Round 1 Draw' ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },

    currentReport() {
      if (Array.isArray(this.filterReport))
        return this.filterReport[this.filterReport.length - 1].report_url;
      else return this.reports.report_url;
    },
    currentTitle() {
      if (Array.isArray(this.filterReport))
        return this.filterReport[this.filterReport.length - 1].report_title;
      else return this.reports.report_title;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_TIC_BASE +
          this.tourCode +
          "/" +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entryw-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );
          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.tourCode +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.tourCode  +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.tourCode  +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_TIC_BASE +
            this.tourCode  +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_TIC_BASE +
            this.tourCode  +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_TIC_BASE +
            this.tourCode  +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  //  metaInfo() {
  //    //Injects Meta and Title to Head
  //    return {
  //      title: this.title.content,
  //      meta: [
  //        {
  //          name: this.description.name,
  //          content: this.description.content
  //        }
  //      ]
  //    };
  //  },
  created() {
    localStorage.clear();
    this.myFunction();
    window.addEventListener('resize', this.myFunction);
    setInterval(
      console.log('In Interval 270'),
      this.updateSize, 12000,
      console.log('In Interval 272')
    );
    // this.getAllTourns();
    this.reportListText();
    // this.getReports(current);
  },
};
</script>
<style scoped>
.LiveScoreMessage {
  position: absolute;
  bottom: 66px;
  background: red;
  width: 100%;
  text-align: center;
  color: #fff;
}
.LiveScoreMessage > p {
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.TournDrop {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}
::v-deep li.nav-item.tabsStyle.tabWidth {
  width: 50%;
}
::v-deep .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
}
::v-deep .tabone {
  width: 27%;
}
::v-deep .tabtwo {
  width: 40%;
}
::v-deep .tabthree {
  width: 33%;
}
.homeHeight {
  height: 669px;
}
.HomehomeReport-list {
  border: 1px solid #E6E6E6;
}
::v-deep li.nav-item.tabsStyle {
  /* width: 33%; */
}
::v-deep li.nav-item.tabsStyle > a{
  /* padding-left: 13px!important;
  padding-right: 10px!important; */
}
::v-deep .nav-tabs .nav-link:hover {
  cursor: default;
  background-color: #dfe1e6;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  color: #00433e !important;
  padding-left: 18px;
  padding-right: 20px;
  text-align: center;
}
::v-deep .nav-tabs .nav-link.active {
  cursor: default;
  background-color: #fff;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #34caa9 !important;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  padding-top: 14px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs .nav-link {
  cursor: default;
  background-color: #E6E6E6;
  color: #B8B8B8 !important;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
/* ::v-deep .tabWhite { 
  padding-top: 0em;
  height: 581px;
} */
.home {
  background-color: #fff;
}
.title {
  font-weight: 500;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  color: #000;
  font-size: 23pt;
  margin-top: 1em;
  line-height: 1.1;
  /* letter-spacing: 2px; */
}

.ad {
  padding: 15px 0; 
}

.bottomAdBanner {
  width: 100%;
}
.sections {
  padding: 30px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
@media only screen and (max-height: 1024px) {
  .homeReport-list {
    /* height: auto !important; */
  }
}
@media only screen and (min-height: 501px) {
.homeContainer.container {
  padding-left: 0px!important;
  padding-right: 0px!important;
}
}
@media only screen and (max-height: 500px) {
  .mobileWidth {
    padding-left: 0px;
    padding-right: 20px;
  }
}
@media only screen and (max-height: 430px) {
::v-deep .nav-tabs .nav-link.active {
  cursor: default;
  background-color: #fff;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #34caa9 !important;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  padding-top: 15px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs .nav-link {
  cursor: default;
  background-color: #E6E6E6;
  color: #B8B8B8 !important;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 35px;
  border: none!important;
}
}
.mobWidgetPad {
  height: 450px!important;
}


@media only screen and (max-width: 480px) {
  .bottomAdBanner{
    padding: 15px 0;
  }
  .hero-plus__html-content iframe {
    width: 94%!important;
    height: 980px!important;
  }
}

@media only screen and (max-width: 419px) {
  ::v-deep .nav-tabs .nav-link.active {
    cursor: default;
    background-color: #fff;
    border-bottom-color: transparent;
    border-radius: 0px 0px 0px 0px !important;
    height: 38px;
    font-family: "proxima-nova, sans-serif", sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    color: #34caa9 !important;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 0px solid #dee2e6;
    border: none!important;
  }
  ::v-deep .nav-tabs .nav-link {
    cursor: default;
    background-color: #E6E6E6;
    color: #B8B8B8 !important;
    border-bottom-color: transparent;
    border-radius: 0px 0px 0px 0px !important;
    height: 38px;
    font-family: "proxima-nova, sans-serif", sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 0px solid #dee2e6;
    text-align: center;
    border: none!important;
  }
}
@media only screen and (max-width: 375px) {
  .mobileWidth {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
