<template>
  <div id="reportTable">
    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>

    <!-- Reports Table -->
    <table>
      <thead v-bind:style="{ 'background-color':  reportColour}" v-if="home != true">
        <tr>
          <template>
            <th v-if="data.match_play === 'Y'">
              Group
            </th>
            <th v-else>
              Match
            </th>
            <th style="text-align:center">
              Time
            </th>
            <th v-if="data.multi_course === 'Y'">
              Course
            </th>
            <th>
              Tee
            </th>
            <th>
              Player(s)
            </th>
          </template>
        </tr>
      </thead>
      <tbody>

        <template v-for="(tabledata, index) in Eds">
            <tr @click.stop="rowClicked(index)" :key="index" :class="{ trTableHome: home === true}" >
            <template v-if="tablePick === 'Draw' && data.match_play === 'N'"> 
                <td>{{tabledata[0].match}}</td>
                <td style="text-align:center">{{tabledata[0].time}}</td>
                <template v-if="data.multi_course === 'Y'">
                <td>
                    <span :class='tabledata[0].course_colour'>
                    {{ tabledata[0].course }}
                    </span>
                </td>
                </template>
                <template v-if="data.multi_course === 'Y'">
                <td class="multiTee">{{tabledata[0].tee}}</td>
                </template>
                <template v-else>
                <td>{{tabledata[0].tee}}</td>
                </template>
                <td class="PlayerPad">
                <p v-for="(player, index2) in tabledata" :key="index2" class="noMargin">

                    <template v-if="bbTeamEvent === 'Y'">
                    <div class="DrawTeamName">
                        {{ player.name }}
                    </div>
                    <div class="pairName" v-show='toggle'>
                        <span class="namePair" v-if="player.pair_1_name.length > 0">
                        {{player.pair_1_name}}
                        </span>
                        <span class="namePair" v-if="player.pair_2_name.length > 0">
                        {{player.pair_2_name}}
                        </span>
                        <span class="namePair" v-if="player.pair_3_name.length > 0">
                        {{player.pair_3_name}}
                        </span>
                        <span class="namePair" v-if="player.pair_4_name.length > 0">
                        {{player.pair_4_name}}
                        </span>
                    </div>
                    </template>
                    <template v-else>
                    <template v-if="player.profile === 'Y'">
                        <a :href="'/player-profiles/' + player.playerno" target="_blank" class="playerLink">
                        {{ player.name}}
                        <span v-if="player.pro_ind === 'Am'">(a)</span>
                        <span class="arrowLink">
                            <a :href="'/player-profiles/' + player.playerno" target="_blank" class="linkPlayerProfile">
                            <span class="iconRight">
                                <font-awesome-icon
                                class="icon"
                                :icon="['fa', 'chevron-right']"
                                />
                            </span>
                            </a>
                        </span>
                        </a>
                    </template>
                    <template v-else>
                        {{ player.name}}
                        <span v-if="player.pro_ind === 'Am'">(a)</span>
                    </template>
                    </template>
                    <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{female : player.gender === 'F', male : player.gender === 'M'}"
                    >•</span>
                </p>
                </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'Y'">
                <td>
                {{ tabledata[0].match }}
                </td>
                <td class="centerTime">
                {{ tabledata[0].time }}
                </td>
                <td v-if="data.match_play_team == 'Y'">
                <div>
                    <span
                    class="teamcolourPad"
                    :style="
                    'background-color:' +
                    tabledata[0].tmp_colour_1
                    ">
                    {{tabledata[0].tmp_nat_1}}
                    </span>
                </div>
                <div>
                    <span
                    class="teamcolourPad"
                    :style="
                    'background-color:' +
                    tabledata[0].tmp_colour_2
                    ">{{tabledata[0].tmp_nat_2}}</span>
                </div>
                </td>
                <td class="">
                {{ tabledata[0].tee }}
                </td>
                <td class="noPadLeft">
                <div>
                    {{ tabledata[0].name_1 }}
                </div>
                <div>
                    {{ tabledata[0].name_2 }}
                </div>
                </td>
            </template>
            </tr>
        </template>

      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import ads from '@/js/ads.js'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import _ from 'lodash'
export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
  ],
  componentss: [
    lodash,
    VueLodash,
    _,
  ],
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win",
      ads:ads,
      reportColour: process.env.VUE_APP_REPORT_MAIN_COLOUR
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Tournament")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    pftroundsPlayed() {
      return parseInt(this.data.pft_round);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
          // console.log("reportData")
          // console.log(reportData)
        return  _.groupBy(reportData, 'match');
      } else {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, 8);
        return  _.groupBy(reportData, 'match');
      }
    },

  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
td {
  padding-top: 25px!important;
}
tr.trTableHome {
  border-bottom: 1px solid rgba(0, 0, 0, .2) ;
}
td.centerTime {
  text-align: center;
}
a.playerLink {
    color: #000 !important;
}
a.nameLink{
  color: #000 !important;
}
.catSection {
  padding-top: 16px!important;
}
.homeEntriesPos {
  margin-top: 8px!important;
}
.homeEntriesStatus {
  vertical-align: middle;
}
a.homeEntriesName {
  margin-top: 0px!important;
}
span.multiCourseSpanTeam {
  margin-top: 10px;
  float: right;
}
span.multiCourseSpan {
  float: right;
  margin-top: -17px;
}
span.multiCourseSpanTeam.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpan.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1E90FF;
}
.R {
  color: #BB0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
td.posDown {
  position: relative;
  padding-top: 20px;
}
/* td.posDownWinnings {
  margin-top: 10px;
  position: absolute;
} */
span.posDown {
  position: relative;
  top: 8px;
}
td.ParBlack{
  position: relative;
  padding-top: 20px;
}
span.ParBlack{
  position: relative;
  top: 8px;
}
::v-deep span.down.posDown {
  position: relative;
  top: 8px;
}
td.down.posDown {
  position: relative;
  padding-top: 20px;
}
::v-deep span.up.posDown {
  position: relative;
  top: 8px;
}
td.up.posDown {
  position: relative;
  padding-top: 20px;
}
.scorePos {
  margin-left: 0px!important;
}
span.FlagScores {
  margin-top: 6px;
  display: block;
}
::v-deep button#__BVID__32__BV_toggle_ {
  line-height: 35px;
  padding-bottom: 0;
  height: 40px;
}
td.drawName > a {
  margin-top: 1px;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px!important;
  padding-top: 7px!important;
  padding-bottom: 22px!important;
}
.title {
  text-align: center;
  background-color: #00433e;
  color: white;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  /* background-color: #00433e; */
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #00433e; */
  color: #ffffff !important;
  text-align: left;
  padding-bottom: 12px;
  padding-top: 12px;
  z-index: 2;
}

.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  color: #7d7d7d;
  font-weight: 300;
}
.drawName {
  font-size: 14px !important;
}
table {
  width: 100%;
}

thead > tr {
  color: white;
}
td:first-child {
  text-align: left;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: left;
}
td:nth-child(3) {
  text-align: left;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #fff;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: black;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
}
::v-deep td > a {
  /* margin-top: 6px; */
  display: block;
}
td {
  padding: 12px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  cursor: pointer;
  text-align: left;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 600;
}

td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
::v-deep a:hover{
      color: #00b588!important;
      text-decoration: none;
}
@media only screen and (max-width: 768px) {
  td {
    font-size: 12px !important;
  }
  th {
    font-size: 13px;
  }
  .mobHide {
    display: none;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>